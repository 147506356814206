<template>
  <div class="newsinfo" v-loading.fullscreen.lock="newLoading">
    <div class="title">
      <div class="title-top">
        新闻中心
      </div>
      <div class="title-description">
        了解有关晓果智学的所有最新公司动态
      </div>
    </div>
    <div class="newsinfo-inner">
      <!-- 内容 -->
      <div class="main">
        <div class="main-title">
          {{ val.title }}
        </div>
        <div class="main-2">
          <div class="main-2-1">
            <img class="img1" :src="fontAssetsURL(`news/图层 905.png`)" alt="" />
            {{ val.newsTime?val.newsTime:val.pushTime }}
          </div>
          <div class="main-2-2">
            <img class="img2" :src="fontAssetsURL(`news/图层 906.png`)" alt="" />
            {{ val.count }}
          </div>
        </div>
        <!-- class="main-content" -->
        <div class="ql-editor main-content" v-html="infoHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gethomePageNewsDetail } from "../server/api";
export default {
  data() {
    return {
      newLoading:false,
      val: {}, //本页对象
      infoHtml: "", //获取的内容 文章
    };
  },
  created() {
    
  },
  mounted() {
    this.$nextTick(() => {
      this.getIframe();
    });
  },
  methods: {
    gethomePageNewsFun(){
      this.newLoading = true
      gethomePageNewsDetail({id:this.$route.query.id}).then(res=>{
        this.infoHtml = res.data[0].content
        this.newLoading = false;
      })
    },
    getIframe() {
      console.log(document.querySelector(".ql-video> video"));
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.val = vm.$route.query;
      vm.gethomePageNewsFun()
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/newsinfo.styl'
.main-content >>> .ql-video
    width: 100%;
    height 500px
</style>
